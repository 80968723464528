<template>
  <div>
    <TitleMore
      :title="title"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <div
        class="itemText"
		style="padding: 10px 0;margin-bottom: 10px;height: 21px;"

        v-for="(item,index) in dataList"
        :key="index"
      >
        <div
          style="display:flex;align-items: center;"
          @click="$goto('/detailShow',{id:item.id})"
        >
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <p class="itemTextContent" style="display: flex;">
            {{item.title}}
            <i
              class="pic"
              v-show="ispopular=='科普知识'"
            ></i>
            <i
              class="video"
              v-show="ispopular=='科普知识'"
            ></i>
          </p>
        </div>
        <p v-if="ispopular!='科普知识'" class="itemTextTime">{{item.publishDate || ""}}</p>
      </div>
      <el-pagination
        :current-page="page"
        :page-size="size"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      title: [],
      dataList: [],
      page: 0,
      size: 15,
      total: 0,
      ispopular: "",
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.title.push(this.$route.query.name);
    this.getselfDyList(this.$route.query.type);
    this.ispopular = this.$route.query.name;
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val-1;
      this.getselfDyList(this.$route.query.type);
    },
    //本会动态
    getselfDyList(type) {
      apiUrls
        .selfDynamicList({ node: type, size: this.size, page: this.page })
        .then((res) => {
          this.total = res.results.pager.totalElements;
          this.dataList = res.results.data;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.selfDynamicMain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: bold;
    color: #333333;
    font-size: 20px;
    height: 30px;
    text-align: center;
    margin-top: 5px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: #ababab;
  }
}
.itemTextContent {
  position: relative;
  margin-left: 17px;
  &:hover {
  	color: #d1292e;
  }
  i {
	  display: inline-block;
  }
}
.pic {
  cursor: pointer;
  display: block;
  width: 22px;
  height: 18px;
  position: relative;
  top: 0px;
  background: url("../../assets/img/pic.png") no-repeat 0 center;
  margin-left: 10px;
}
.video {
  cursor: pointer;
  display: block;
  width: 22px;
  position: relative;
  top: 0px;
  height: 18px;
  background: url("../../assets/img/video.png") no-repeat 0 center;
  margin-left: 10px;
}
// .itemText {
//   // padding: 20px;
//   display: flex;
//   align-items: center;
// }
</style>
